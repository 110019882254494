<template>
    <div style="padding: 20px;">
        <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon>
        <template>
            <TableCommon class="art" isMargin="20px" :tableColumn="tableColumn" :tableData="tableData"></TableCommon>
        </template>
        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from '../../../components/TableCommon.vue';
export default {
    data() {
        return {
            vkey: 'toolsCountPage',
            formInline: {
                name: '',
                pageNum: 1,
                pageSize: 10
            },
            formList: [
                { label: '工具名称', model: 'name', placeholder: '请输入工具名称', type: 'input' },
                // { label: '工具名称', model: 'type', placeholder: '请选择', type: 'select', options: [
                //     { value: '', label: '全部'}]
                // }
            ],
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '工具名称', prop: 'name', align: 'center' },
                { label: '打开次数', prop: 'openCount', align: 'center' },
                { label: '使用次数', prop: 'useCount', align: 'center' }
            ]
        }
    },
    components: {
        TableCommon,
        SearchCommon
    },
    computed: {
        tableData(){
            return this.$store.state.manage.views[this.vkey].list || []
        },
        total() {
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoadList()
    },
    methods: {
        // 列表
        onLoadList(){
            this.$store.dispatch('manage/list', {
                vkey: this.vkey,
                reset: true,
                query: this.formInline,
                interfaceName: 'getToolPageTracking'
            })
        },
        // 搜索
        onSearch() {
            this.formInline.pageNum = 1;
            this.onLoadList();
        },
        // 清空
        onResetBtn() {
            this.formInline = {
                name: '',
                pageNum: 1,
                pageSize: 18
            }
            this.onLoadList()
        },
        // 分页
        handleCurrentChange(val){
            this.formInline.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
.art {
   /deep/ .el-table {
        .el-table__body-wrapper {
            .el-table__body tr td {
                padding: 10px !important;
            }
        }
    } 
}
</style>